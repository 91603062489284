import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  NewUserEmailVerification,
  LoggingExistingUserIn,
  RecoverEmail,
  ActionCodeError,
  LoginModal,
  Unsubscribe
} from 'components';
import { FirebaseContext } from 'context';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { clearQueryParams, formatName } from 'utils';
import { defaultColors } from 'styles';
import { signInWithEmailLink } from 'firebase/auth';

export default () => {
  const [userEmail, setUserEmail] = useState('');
  const [event, setEvent] = useState(null);
  const [emailRecovered, setEmailRecovered] = useState(false);
  const [newUserEmailVerified, setNewUserEmailVerified] = useState(false);
  const [showNewUserEmailVerificationModal, setShowNewUserEmailVerificationModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoggingExistingUserInModal, setShowLoggingExistingUserInModal] = useState(false);
  const [showRecoverEmailModal, setShowRecoverEmailModal] = useState(false);
  const [actionCodeError, setActionCodeError] = useState({ mode: null, message: null });
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const { loading, firebase, user } = useContext(FirebaseContext);
  const {
    mode,
    oobCode: actionCode,
    continueUrl,
    unsubscribe
  } = queryString.parse(useLocation().search);
  const { eventSlug: eventSlugIfUserRegisteredUsingAnotherDeviceOrBrowser } = queryString.parse(
    continueUrl?.slice(continueUrl?.indexOf('?'))
  );
  const colors = defaultColors;

  useEffect(() => {
    if (unsubscribe) {
      setShowUnsubscribeModal(true);
    }
  }, [unsubscribe]);

  useEffect(() => {
    if (!loading && firebase) {
      const existingUserLocalStorageData = JSON.parse(
        window.localStorage.getItem('existingUserIPHAForum')
      );
      const newUserLocalStorageData = JSON.parse(window.localStorage.getItem('newUserIPHAForum'));
      const referringUrl = JSON.parse(
        window.localStorage.getItem('referringUrlIPHAForumS4E2')
      )?.data;
      switch (mode) {
        case 'signIn':
          if (existingUserLocalStorageData?.email || newUserLocalStorageData?.email) {
            signInWithEmailLink(
              firebase.auth,
              existingUserLocalStorageData?.email || newUserLocalStorageData?.email,
              window.location.href
            )
              .then((result) => {
                const { isNewUser } = result._tokenResponse;
                if (isNewUser) {
                  if (newUserLocalStorageData) {
                    setShowNewUserEmailVerificationModal(true);
                  }
                  const formattedName = formatName(newUserLocalStorageData.name);
                  // Creates account in Firestore Database
                  firebase.registration
                    .createAccount({
                      email: newUserLocalStorageData.email.toLowerCase().trim(),
                      name: formattedName.trim(),
                      profession: '',
                      referringUrl,
                      uid: result.user.uid,
                      company: newUserLocalStorageData.company.trim(),
                      eid: newUserLocalStorageData.eid
                    })
                    .then(() => {
                      firebase?.auth?.currentUser?.reload().then(() => {
                        setEvent({
                          eid: newUserLocalStorageData.eid,
                          slug: newUserLocalStorageData.eventSlug
                        });
                        setNewUserEmailVerified(true);
                        window.localStorage.removeItem('newUserIPHAForum');
                        window.localStorage.removeItem('referringUrlIPHAForumS4E2');
                        clearQueryParams();
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                      // TODO: Check if user was created in Auth and delete them if so.
                      setShowNewUserEmailVerificationModal(false);
                      clearQueryParams();
                    });
                } else if (!isNewUser) {
                  if (existingUserLocalStorageData.eid) {
                    setEvent({
                      eid: existingUserLocalStorageData.eid,
                      slug: existingUserLocalStorageData.eventSlug
                    });
                  }
                  if (existingUserLocalStorageData) {
                    setShowLoggingExistingUserInModal(true);
                  }
                  window.localStorage.removeItem('existingUserIPHAForum');
                  if (newUserLocalStorageData) {
                    window.localStorage.removeItem('newUserIPHAForum');
                  }
                  clearQueryParams();
                }
              })
              .catch((error) => {
                console.error(error.message);

                if (showNewUserEmailVerificationModal) {
                  setShowNewUserEmailVerificationModal(false);
                }

                if (showLoggingExistingUserInModal) {
                  setShowLoggingExistingUserInModal(false);
                }

                if (existingUserLocalStorageData) {
                  window.localStorage.removeItem('existingUserIPHAForum');
                }

                if (newUserLocalStorageData) {
                  window.localStorage.removeItem('newUserIPHAForum');
                }

                clearQueryParams();

                switch (error.code) {
                  case 'auth/invalid-action-code':
                    setActionCodeError({
                      mode,
                      message: `Invalid or expired link.<br />Please ${
                        newUserLocalStorageData ? 'register' : 'login'
                      } again.`
                    });
                    break;
                  default:
                    setActionCodeError({
                      mode,
                      message: error.message
                    });
                    break;
                }
              });
          } else if (!firebase?.auth?.currentUser) {
            // User registered or tried to log in on another device/browser
            setShowLoginModal(true);
          } else if (firebase?.auth?.currentUser) {
            clearQueryParams();
          }
          break;
        case 'recoverEmail':
          setShowRecoverEmailModal(true);
          firebase.emailHandler
            .checkActionCode(actionCode)
            .then(({ data }) => {
              const recoveredEmail = data.email;
              return firebase.emailHandler
                .applyActionCode(actionCode)
                .then(() => firebase.userProfile.updateEmail(recoveredEmail))
                .then(() => {
                  setEmailRecovered(true);
                  setUserEmail(recoveredEmail);
                });
            })
            .catch((error) => {
              console.error(error);
              setShowRecoverEmailModal(false);
              switch (error.code) {
                case 'auth/invalid-action-code':
                  setActionCodeError({
                    mode,
                    message: 'Invalid or expired link.<br />Please try again.'
                  });
                  break;
                default:
                  setActionCodeError({
                    mode,
                    message: error.message
                  });
                  break;
              }
            });
          break;
        default:
          break;
      }
    }
  }, [loading, firebase]);

  return (
    <>
      <Modal
        hideCloseButton
        openWithoutTrigger={showUnsubscribeModal}
        modalContent={<Unsubscribe />}
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showNewUserEmailVerificationModal}
        modalContent={
          <NewUserEmailVerification
            setShowNewUserEmailVerificationModal={setShowNewUserEmailVerificationModal}
            newUserEmailVerified={newUserEmailVerified}
            user={user}
            event={event}
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showLoggingExistingUserInModal}
        modalContent={
          <LoggingExistingUserIn
            continueUrl={continueUrl}
            setShowLoggingExistingUserInModal={setShowLoggingExistingUserInModal}
            event={event}
          />
        }
      />
      <Modal
        hideCloseButton
        // disableCloseOnClickOutside
        openWithoutTrigger={showLoginModal}
        redirectIfNotAuthorised
        modalContent={
          <LoginModal
            colors={colors}
            eventSlug={eventSlugIfUserRegisteredUsingAnotherDeviceOrBrowser}
            setShowLoginModal={setShowLoginModal}
            userRegisteredUsingAnotherDeviceOrBrowser
          />
        }
      />
      <Modal
        hideCloseButton
        disableCloseOnClickOutside
        openWithoutTrigger={showRecoverEmailModal}
        modalContent={
          <RecoverEmail
            colors={colors}
            emailRecovered={emailRecovered}
            setShowRecoverEmailModal={setShowRecoverEmailModal}
            userEmail={userEmail}
          />
        }
      />
      <Modal
        hideCloseButton
        openWithoutTrigger={actionCodeError.message}
        modalContent={
          <ActionCodeError
            colors={colors}
            actionCodeError={actionCodeError}
            setActionCodeError={setActionCodeError}
          />
        }
      />
    </>
  );
};
