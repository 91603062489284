import React, { useContext } from 'react';
import styled from 'styled-components';
import { H3, P, breakpoints } from 'styles';
import { LocalContext } from 'context';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const EYSponsor = () => {
  const { theme } = useContext(LocalContext);
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "assets/images/ey-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <SectionWrapper $theme={theme} id="info">
      <Title $theme={theme}>Our sponsor</Title>
      <Content>
        <Description>
          <GatsbyImage image={getImage(data.logo)} />
          <P>
            EY exists to build a better working world, helping to create long-term value for
            clients, people and society and build trust in the capital markets. Powered by data,
            technology and an extensive partner ecosystem, our diverse EY teams in over 150
            countries provide trust through assurance and help clients grow, transform and operate.
            Working across assurance, consulting, law, strategy, tax and transactions, EY teams ask
            better questions to find new answers for the complex issues facing our world today.
          </P>
        </Description>
      </Content>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  background: ${({ $theme }) => $theme.bg};
  padding: 3rem 1.75rem;
  width: 100%;
  p {
    color: ${({ $theme }) => $theme.textMuted};
    font-family: 'Open Sans', sans-serif;
  }
  ${breakpoints.lg} {
    padding: 6.25rem 9rem;
  }
`;

const Title = styled(H3)`
  color: ${({ $theme }) => $theme.fg};
  margin-bottom: 3rem;
  text-align: center;
  width: 100%;
`;

const Content = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  row-gap: 1.25rem;
  p {
    color: #161d23;
  }
`;

const VideoWrapper = styled.div`
  border: 3px solid #a58a4b;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
`;

const Description = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .gatsby-image-wrapper {
    height: auto;
    margin-bottom: 1.5rem;
    max-width: 160px;
    width: 100%;
    img {
      object-fit: contain !important;
    }
  }
`;

export default EYSponsor;
